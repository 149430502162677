import { useEffect, useState } from "react";
import { useWallet } from "use-wallet";
import Web3 from "web3";
import config from "../config";
import erc from "../pages/abi/erc20.json";

const getShortWallet = (address) => {
  return `${address.substring(0, 5)}.... ${address.substring(
    address.length - 5,
    address.length
  )}`;
};

const getHandSomeNumber = (number) => {
  return (number / 1e18).toFixed(2);
};
const Header = () => {
  const wallet = useWallet();

  const [balannce, setbalannce] = useState(0);
  const balance = async () => {
    const web3 = new Web3(Web3.givenProvider);
    const busdToken = new web3.eth.Contract(erc, config.BUSD_ADDRESS);

    const balandce = await busdToken.methods.balanceOf(wallet.account).call();
    console.log(balandce);
    setbalannce(balandce / 1e18);
    // return balannce;
  };

  useEffect(() => {
    connectWallet();
  }, []);

  useEffect(() => {
    if (wallet.status == "connected") {
      balance();
    }

    return () => {};
  }, [wallet.status, wallet.account, wallet]);

  const connectWallet = () => {
    if (window.ethereum) {
      wallet.connect();
    } else {
      wallet.connect("walletconnect");
    }
  };
  return (
    <section className="top_section_outer">
      <nav className="nav-es navbar navbar-expand-lg">
        {/* <li>
          <a
            href={`https://roadmap-rocky.pages.dev/`}
            target="_blank"
            rel="noreferrer"
          >
            <img className="map" src="../map.png" alt="icon" />
          </a>
        </li> */}
        <div className="container-fluid">
          <div className="nav-header"></div>
          <div className="nav-right">
            <div className="topbar-button">
              <div className="ds-flex ">
                {/* {wallet.account ? (
                  <div className="ds-flex-col flex-header-scu">
                    <p>Wallet: {getShortWallet(wallet.account)}</p>
                    <p>BUSD Balance: {balannce}</p>
                  </div>
                ) : null} */}
                <button
                  className="btn btn-a0 text-uppercase culo-button background-donate"
                  onClick={() => {
                    if (wallet.account) {
                      wallet.reset();
                    } else {
                      connectWallet();
                    }
                  }}
                >
                  {wallet.account ? "Disconnect" : "Connect"}
                </button>
                <a
                  className="btn btn-a0 text-uppercase culo-button background-donate"
                  target={"_blank"}
                  href="https://roadmap-rocky.pages.dev/"
                  rel="noreferrer"
                >
                  ROADMAP
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </section>
  );
};

export default Header;
