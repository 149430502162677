export default {
  CONTRACT_ADDRESS: "0x01db24d451ADcdA6B7D9c438662B2a31e6763609", //staking contract address
  BUSD_ADDRESS: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56", // usdt token address
  DEFAULT_REFERRAL: "0x04fb8068d32ccC094b766B7aFBe76588A01d325F", // default referrer
  DONATE_ADDRESS: "0x04fb8068d32ccC094b766B7aFBe76588A01d325F",

  OVERFLOW_WITHDRAWTIME: 8 * 24 * 60 * 60,
  ONE_DAY_WITHDRAWTIME: 24 * 60 * 60,

  BASE_PROFIT_PERCENT: 126,
  CHAINID: 56,
};
